








































































import { Component, Prop, Vue } from "vue-property-decorator";
import Currency from '@/components/Currency.vue'
import { RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState(['user', 'business']),
  },
  metaInfo: {
    title: 'Bienvenido',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
  data() {
    return {
      pais:null,
      sale: null,
      venta: null,
      tipo: null,
      select1: ['Entre $0 y $2 Millones', 'Entre $2 y $10 Millones', 'Entre $10 y $20 Millones', 'Entre $20 y $50 Millones', 'Entre $50 y $200 Millones', 'Más de $200 Millones'],
      select2: ['Factoring', 'Crédito con garantía', 'Crédito sin garantía']
    }
  },
  beforeMount() {
    this.$store.commit('setBusiness', null)
    // @ts-ignore
    this.$data.pais = RedCapital[SelectedCountry].Name 
  },
  components: {
    Currency,
  }
})

export default class Filter extends Vue {}
